import React from "react";
import { graphql } from "gatsby";

import IndexPage from "gatsby-theme-podcast/src/pages/index";

export default function Index({ data: { allEpisode, allMarkdownRemark } }) {
  return (
    <>
      {false && (
        <div className="lndl-alert">
          La terza stagione sta per cominciare!
          <br />
          <strong>I NUOVI EPISODI DA SABATO 17 SETTEMBRE</strong>
        </div>
      )}
      <IndexPage data={{ allEpisode, allMarkdownRemark }} />
    </>
  );
}

export const indexQuery = graphql`
  query {
    allEpisode {
      totalCount
      nodes {
        id
        title
        description
        number
        fields {
          slug
        }
      }
    }
    allMarkdownRemark {
      edges {
        node {
          html
          frontmatter {
            id
            title
            image {
              childImageSharp {
                original {
                  src
                }
                fluid(maxWidth: 700) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
